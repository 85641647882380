import React from 'react';

import { Link } from "react-router-dom";
import {isMobile} from 'react-device-detect';

import Icon from "../../ui/Icon";

import classNames from "classnames";
import classes from './style/topMenu.module.scss';

const TopMenu = () => {
    
    let mobileMenuList = [
        {
            title: "VideoChat",
            icon: "icon-menu-video",
            link: "/videochat"
        },
        { link: '/balance', title: 'Balance', icon: 'icon-settings'}
    ]

    return (
        <header>
            <div className="container">
            {
                isMobile
                ?
                    <div className={classNames(classes["mobile_header_menu"], "mobile_header_menu")}>
                        <ul className={classes["mobile_header_menu_buttons"]}>
                        {mobileMenuList.map((item, index) => (
                            <li key={index}>
                                <Link to={item.link}>
                                    <div className={classes["image"]}>
                                        <Icon id={item.icon} className={item.icon} />
                                    </div>
                                    <span>
                                        {item.title}
                                    </span>
                                </Link>
                            </li>
                        ))}
                        </ul>
                    </div>
                : null
            }
            </div>
        </header>
    );
}

export default TopMenu;
