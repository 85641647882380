import React, { useEffect } from 'react';
import PageWrap from '../../app/PageWrap';
import LinkButton from "../../ui/Button/LinkButton";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const MainPage = () => {
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state.user.info);
    const user = JSON.parse(localStorage.getItem("userInfo"));

    useEffect(() => {
        if(!user || (userInfo && !userInfo?.isLoggedIn)){
            navigate('/login')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, userInfo]);
    
    return (
        <PageWrap>
            <div className='container'>
                <div className="main-container">
                    {
                        !user || (userInfo && !userInfo?.isLoggedIn) ? (
                            <LinkButton addClasses={['capitalize']} to="/login">login</LinkButton>
                            ) : (
                            <LinkButton addClasses={['capitalize']} to="/videochat">to videochat page</LinkButton>
                        )
                    }
                </div>
            </div>
        </PageWrap>
    );
}

export default MainPage;
