export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const GetConversationRequest = 'GET_CONVERSATION_REQUEST'
export const GetConversationSuccess = 'GET_CONVERSATION_SUCCESS'
export const GetConversationFailed = 'GET_CONVERSATION_FAILED'
// types.js

export const UPDATE_VIDEO_STATUS_REQUEST = 'UPDATE_VIDEO_STATUS_REQUEST';
export const UPDATE_VIDEO_STATUS_SUCCESS = 'UPDATE_VIDEO_STATUS_SUCCESS';
export const UPDATE_VIDEO_STATUS_FAILURE = 'UPDATE_VIDEO_STATUS_FAILURE';

export const SAVE_LOCAL_MEDIA_STREAM = 'SAVE_LOCAL_MEDIA_STREAM';
export const CLEAR_LOCAL_MEDIA_STREAM = 'CLEAR_LOCAL_MEDIA_STREAM';

export const UPDATE_SDPA_STATUS_REQUEST = 'UPDATE_SDPA_STATUS_REQUEST';
export const UPDATE_SDPA_STATUS_SUCCESS = 'UPDATE_SDPA_STATUS_SUCCESS';
export const UPDATE_SDPA_STATUS_FAILURE = 'UPDATE_SDPA_STATUS_FAILURE';
