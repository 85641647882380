import {SAVE_LOCAL_MEDIA_STREAM,CLEAR_LOCAL_MEDIA_STREAM} from "../actions/types";


const initialState = {
    localMediaStream: null,
};

const mediaReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_LOCAL_MEDIA_STREAM:
            return {
                ...state,
                localMediaStream: action.payload,
            };
        case CLEAR_LOCAL_MEDIA_STREAM:
            state.localMediaStream?.getTracks().forEach( track =>{
                track.stop()
            });
           state.localMediaStream=null
            return {
                ...state,
                localMediaStream: null,
            };
        default:
            return state;
    }
};

export default mediaReducer;