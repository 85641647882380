import axios from "axios";
import { fetchUserFailure, fetchUserRequest, fetchUserSuccess } from "./actions";

export const getUser = (userId) => {
    return (dispatch) => {
      dispatch(fetchUserRequest());
      const user = JSON.parse(localStorage.getItem("userInfo"));
      let config = {
        headers: {
            "Content-type": "application/json",
            "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
        },
    };
    if(user){
        config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${user.token}`,
                "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
            },
        };
    }
      axios
        .get(
          `https://api.surrogacy-platform.com/api/users/getUser/${user.userId}`, config
        )
        .then((response) => {
          const info = response.data;
          dispatch(fetchUserSuccess(info));
        })
        .catch((error) => {
          dispatch(fetchUserFailure(error.message));
        });
    };
  };