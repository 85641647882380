import {
    BrowserRouter as Router,
    useRoutes,
} from "react-router-dom";
import { Provider } from 'react-redux'; 
import store from '../store'; 
import LoginPage from "../pages/LoginPage";
import MainPage from "../pages/MainPage";

import {SocketContext, socket} from '../context/socket';
import BalancePage from "../pages/BalancePage";
import Room from "../pages/Videochat/components/Room";
import Videochat from "../pages/Videochat";

const Pages = () => {
    let routes = useRoutes([
        {
            path: "/",
            element: <MainPage />
        },
        {
            path: "/balance",
            element: <BalancePage />
        },
        {
            path: "/login",
            element: <LoginPage />
        },
        {
            path: "/videochat",
            element: <Videochat />
        },
        {
            path: "/room/:id",
            element: <Room />
        },

    ]);
    return routes;
};

const AppWrapper = () => {
    return (
        <SocketContext.Provider value={socket}>
            <Provider store={store}>
                <Router>
                    <Pages />
                </Router>
            </Provider>
        </SocketContext.Provider>
    );
};

export default AppWrapper;
