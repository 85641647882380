import { combineReducers } from 'redux';


import loginReducer from './loginReducer';
import videocallReducer from "./videocallReducer";
import mediaReducer from "./mediaReducer";
import userReducer from './userReducer';
import globalReducer from './globalReducer';


const rootReducer = combineReducers({
  user: userReducer,
  auth: loginReducer,
  videocall:videocallReducer,
  media:mediaReducer,
  global: globalReducer

});

export default rootReducer;