import instance from '../context/api'; // Імпортуйте ваш настроєний екземпляр Axios
import {updateVideoStatusFailure,updateVideoStatusSuccess,updateVideoStatusRequest, updateSDPAndGetResponse, updateSDPAndGetSuccess, updateSDPAndGetFailure} from "./actions";

export const updateVideoStatus = (payload) => async (dispatch) => {
    console.log(payload);
    dispatch(updateVideoStatusRequest(payload));
    try {
        const response = await instance.patch('users/update-video-status', payload);
        dispatch(updateVideoStatusSuccess(response.data));
    } catch (error) {
        dispatch(updateVideoStatusFailure(error.message));
    }
};

export const sendSDPAndGetResponse = (payload) => async (dispatch) => {
    dispatch(updateSDPAndGetResponse(payload));
    try {
        const { data } = await instance.post('video/broadcast', payload);
        dispatch(updateSDPAndGetSuccess(data));
        return data;
    } catch (error) {
        dispatch(updateSDPAndGetFailure(error.message));
        console.error('Ошибка при отправке SDP:', error);
        throw error;
    }
};