import { useNavigate, useParams } from "react-router";
import useWebRTC from "../../../hooks/useWebRTC";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { updateVideoStatus } from "../../../actions/videocall";
import { useDispatch } from "react-redux";
import { SocketContext } from "../../../context/socket";

import ACTIONS from "../../../utils/actions";
import { clearLocalMediaStream } from "../../../actions/actions";
import PageWrap from "../../../app/PageWrap";
import Button from "../../../ui/Button";

import classes from '../style/videochat.module.scss'

export default function Room() {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const { id: roomID } = useParams();
    const { localVideoRef } = useWebRTC(roomID);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const socket = useContext(SocketContext);
    const [clientCount, setClientCount] = useState();


    const handleClientCount = useCallback((res) => {
        setClientCount(res.count)
    }, []);

    useEffect(() => {
        socket.on('notification-to-the-donor', (res) => handleClientCount(res));
        return () => {
            socket.off('notification-to-the-donor');
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    const handleCloseChat = async () => {
        try {
            await dispatch(updateVideoStatus({ status: "closed", roomId: roomID, donorId: user.userId }));
            socket.emit(ACTIONS.LEAVE);
            socket.emit('close-video-event', {donorId:user.userId})
            dispatch(clearLocalMediaStream());
            window.location.href = '/videochat';
        } catch (error) {
            console.log("Error while closing chat:", error);
        }
    };

    return (
        <PageWrap>
            <div className="container">
                <div className="main-container">
                    <div className={classes["video-block"]}>
                        <video
                            width="100%"
                            height="100%"
                            ref={localVideoRef}
                            autoPlay={true} playsInline={true} muted={true}
                        />
                        <Button onClick={handleCloseChat}>close chat</Button>
                    </div>
                    <p className={classes["client-counts"]}>Clinets in videochat: <b>{clientCount}</b></p>
                </div>
            </div>
        </PageWrap>
    );
    
}
