import { useContext, useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { saveLocalMediaStream, clearLocalMediaStream } from "../actions/actions";
import ACTIONS from '../utils/actions';
import { SocketContext } from "../context/socket";

export const LOCAL_VIDEO = 'LOCAL_VIDEO';

export default function useWebRTC(roomID, donorId, userId) {
    
    const socket = useContext(SocketContext);
    const dispatch = useDispatch();

    const localMediaStream = useRef(null);
    const localVideoElement = useRef(null); 

    const peerConnections = useRef({});

    const peerMediaElements = useRef({
        [LOCAL_VIDEO]: localVideoElement,
    });

    const handleNewPeer = useCallback(async ({ peerID, createOffer }) => {
        if (peerID in peerConnections.current) {
            return console.warn(`Already connected to peer ${peerID}`);
        }

        peerConnections.current[peerID] = new RTCPeerConnection({
            iceServers: [{ 'urls': 'stun:stun.l.google.com:19302' }],
        });

        peerConnections.current[peerID].onicecandidate = event => {
            if (event.candidate) {
                socket.emit(ACTIONS.RELAY_ICE, {
                    peerID,
                    iceCandidate: event.candidate,
                });
            }
        };

        peerConnections.current[peerID].ontrack = ({ streams: [remoteStream] }) => {
            if (peerMediaElements.current[peerID]) {
                peerMediaElements.current[peerID].srcObject = remoteStream;
            }
        };

        localMediaStream.current.getTracks().forEach(track => {
            peerConnections.current[peerID].addTrack(track, localMediaStream.current);
        });

        if (createOffer) {
            const offer = await peerConnections.current[peerID].createOffer();
            await peerConnections.current[peerID].setLocalDescription(offer);
            socket.emit(ACTIONS.RELAY_SDP, {
                peerID,
                sessionDescription: offer,
            });
        }
    }, [socket, localMediaStream]);

    useEffect(() => {
        socket.on(ACTIONS.ADD_PEER, handleNewPeer);

        return () => {
            socket.off(ACTIONS.ADD_PEER);
        };
    }, [socket, handleNewPeer]);
    const setRemoteMedia = async ({ peerID, sessionDescription: remoteDescription }) => {
        try {
            const peerConnection = peerConnections.current[peerID];
            
            if (!peerConnection) {
                console.error(`No peer connection found for peerID: ${peerID}`);
                return;
            }
    
            // Логирование состояния
            console.log(`Signaling state before setRemoteDescription: ${peerConnection.signalingState}`);
            
            await peerConnection.setRemoteDescription(new RTCSessionDescription(remoteDescription));
    
            console.log(`Signaling state after setRemoteDescription: ${peerConnection.signalingState}`);
    
            if (remoteDescription.type === 'offer') {
                const answer = await peerConnection.createAnswer();
    
                await peerConnection.setLocalDescription(answer);
    
                socket.emit(ACTIONS.RELAY_SDP, { peerID, sessionDescription: answer });
            }
        } catch (error) {
            console.error(`Error setting remote description for peerID ${peerID}:`, error);
        }
    };

    useEffect(() => {
        socket.on(ACTIONS.SESSION_DESCRIPTION, (res) => setRemoteMedia(res));

        return () => {
            socket.off(ACTIONS.SESSION_DESCRIPTION);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    // Обрабатываем ICE-кандидаты
    useEffect(() => {
        socket.on(ACTIONS.ICE_CANDIDATE, ({ peerID, iceCandidate }) => {
            peerConnections.current[peerID]?.addIceCandidate(new RTCIceCandidate(iceCandidate));
        });

        return () => {
            socket.off(ACTIONS.ICE_CANDIDATE);
        };
    }, [socket]);

    useEffect(() => {
        const handleRemovePeer = ({ peerID }) => {
            if (peerConnections.current[peerID]) {
                peerConnections.current[peerID].close();
            }

            delete peerConnections.current[peerID];
            delete peerMediaElements.current[peerID];
        };

        socket.on(ACTIONS.REMOVE_PEER, handleRemovePeer);

        return () => {
            socket.off(ACTIONS.REMOVE_PEER);
        };
    }, [socket]);

    useEffect(() => {
        async function startCapture() {
            localMediaStream.current = await navigator.mediaDevices.getUserMedia({
                video: {
                    width: 1280,
                    height: 720,
                }
            });

            dispatch(saveLocalMediaStream(localMediaStream.current));

            if (localVideoElement.current) {
                localVideoElement.current.volume = 0;
                localVideoElement.current.srcObject = localMediaStream.current;
            }
        }

        startCapture()
            .then(() => socket.emit(ACTIONS.JOIN, { room: roomID }))
            .catch(e => console.error('Error getting userMedia:', e));

        return () => {
            dispatch(clearLocalMediaStream());
            socket.emit(ACTIONS.LEAVE);
        };
    }, [roomID, socket, dispatch]);

    return {
        localVideoRef: localVideoElement,
    };
}
