import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SocketContext } from '../context/socket';
import { setSocketConnection } from '../actions/actions';

export default function useEventObserver() {
    const dispatch = useDispatch();
    const socket = useContext(SocketContext);
    const socketConnected = useSelector((state) => state.global.socketConnected)
    const user = useSelector((state) => state.user.info)

    useEffect(() => {
        if(user && !socketConnected){
            socket.emit('setup', { id: user.id });
            socket.on('connected', () => {
                dispatch(setSocketConnection(true))
                console.log('Socket successfully conncted');
            });
        }
    }, [user, socketConnected]);
}