// VideoChatComponent.js
import {useRef, useContext} from 'react';

import {SocketContext} from '../../../context/socket';
import {useNavigate} from 'react-router';
import {v4} from 'uuid';
import React from 'react';
import {useDispatch} from "react-redux";
import {updateVideoStatus} from "../../../actions/videocall";
import Button from '../../../ui/Button';

export default function PreChat() {
    const navigate = useNavigate();
    const socket = useContext(SocketContext);
    const rootNode = useRef();
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const dispatch = useDispatch();

    const handleCreateRoom = async () => {
        const roomID = v4() + "-" + user.userId;
        try {
            await dispatch(updateVideoStatus({ status: 'ongoing', roomId: roomID, donorId:user.userId }));
            console.log({donorId:user.userId});
            socket.emit('start-video-event', {donorId:user.userId, roomId: roomID})
            navigate(`/room/${roomID}`);
        } catch (error) {
            console.log('Error while creating room:', error);
        }
    };

    return (
        <div ref={rootNode}>
            <p style={{marginBottom: "24px"}}>Press button to start video</p>
            <Button onClick={handleCreateRoom}>Enter to chat</Button>
        </div>
    );
}