import axios from "axios";
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE ,UPDATE_VIDEO_STATUS_REQUEST,UPDATE_VIDEO_STATUS_SUCCESS,UPDATE_VIDEO_STATUS_FAILURE,
SAVE_LOCAL_MEDIA_STREAM,CLEAR_LOCAL_MEDIA_STREAM, FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_FAILURE, UPDATE_SDPA_STATUS_REQUEST, UPDATE_SDPA_STATUS_SUCCESS, UPDATE_SDPA_STATUS_FAILURE} from "./types";

export const loginRequest = (credentials) => ({
    type: LOGIN_REQUEST,
    payload: credentials,
});

export const loginSuccess = (user) => ({
    type: LOGIN_SUCCESS,
    payload: user,
});

export const loginFailure = (error) => ({
    type: LOGIN_FAILURE,
    payload: error,
});

export const fetchUserRequest = () => ({
    type: FETCH_USER_REQUEST,
  });
  
  export const fetchUserSuccess = (info) => ({
    type: FETCH_USER_SUCCESS,
    payload: info,
  });
  
  export const fetchUserFailure = (error) => ({
    type: FETCH_USER_FAILURE,
    payload: error,
  });

export const updateVideoStatusRequest = (payload) => ({
    type: UPDATE_VIDEO_STATUS_REQUEST,
    payload
});

export const updateVideoStatusSuccess = (data) => ({
    type: UPDATE_VIDEO_STATUS_SUCCESS,
    payload: data,
});

export const updateVideoStatusFailure = (error) => ({
    type: UPDATE_VIDEO_STATUS_FAILURE,
    payload: error,
});

export const saveLocalMediaStream = (stream) => ({
    type: SAVE_LOCAL_MEDIA_STREAM,
    payload: stream,
});

export const clearLocalMediaStream = () => ({
    type: CLEAR_LOCAL_MEDIA_STREAM,
});

export const updateSDPAndGetResponse = (payload) => ({
    type: UPDATE_SDPA_STATUS_REQUEST,
    payload,
});

export const updateSDPAndGetSuccess = (data) => ({
    type: UPDATE_SDPA_STATUS_SUCCESS,
    payload: data,
});

export const updateSDPAndGetFailure = (error) => ({
    type: UPDATE_SDPA_STATUS_FAILURE,
    payload: error,
});


export const getBalanceInvoicesRequest = () => ({
    type: 'GET_BALANCE_INVOICES_REQUEST',
  });
  
export const getBalanceInvoices = (id) => async (dispatch) => {
dispatch(getBalanceInvoicesRequest());
try {
    const user = JSON.parse(localStorage.getItem('userInfo'));
    const config = {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
            "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
        },
    };
    const response = await axios.get(`https://api.surrogacy-platform.com/api/admin/translator-invoices/${id}`, config);
    dispatch(getBalanceInvoicesSuccess(response.data));
} catch (error) {
    dispatch(getBalanceInvoicesFailure(error.message));
}
};
  
  const getBalanceInvoicesSuccess = (data) => ({
    type: 'GET_BALANCE_INVOICES_SUCCESS',
    payload: data,
  });
  
  const getBalanceInvoicesFailure = (error) => ({
    type: 'GET_BALANCE_INVOICES_FAILURE',
    payload: error,
  });

  export const logoutRequest = (credentials) => ({
    type: 'LOGOUT_REQUEST',
    payload: credentials,
});
  
export const logoutSuccess = () => ({
    type: 'LOGOUT_SUCCESS'
});
  
export const logoutFailure = (error) => ({
    type: 'LOGOUT_FAILURE',
    error
});

export const logOut = (onSuccessRedirect) => async (dispatch) => {
    const user = JSON.parse(localStorage.getItem('userInfo'));
    const config = {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
            "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
        },
    };

    dispatch(logoutRequest());

    try {
        const response = await axios.patch('https://api.surrogacy-platform.com/api/auth/logout', {}, config);
        
        dispatch(logoutSuccess(response.data));

        if (onSuccessRedirect) {
            onSuccessRedirect('/login');
        }
    } catch (error) {
        dispatch(logoutFailure(error));
    }
}

export const setSocketConnection = (res) => ({
    type: 'SOCKET_CONNECTION',
    payload: res,
});