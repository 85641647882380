import React, { useContext, useEffect } from "react";
import { isMobile } from "react-device-detect";
import AsideMenu from "../components/AsideMenu";
import TopMenu from "../components/TopMenu";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../actions/userActions";
import { SocketContext } from "../context/socket";
import useEventObserver from "../hooks/useEventObserver";

const PageWrap = ({ children }) => {

    const dispatch = useDispatch();

    const userInfo = useSelector((state) => state.user.info);
    const user = JSON.parse(localStorage.getItem("userInfo"));

    const socket = useContext(SocketContext);

    useEventObserver()

    // useEffect(() => {
    //     if(userInfo){
    //         socket.emit('setup', { id: userInfo.id });
    //         socket.on('connected', () => {
    //             console.log('Сокет успешно подключен');
    //         });
    //     }
    //         return () => {
    //             socket.off('connected');
    //         };
    // }, [socket, userInfo]);

    useEffect(() => {
        console.log('useEffect is called');
        if(user && !userInfo){
            dispatch(getUser());
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    return (
        <>
            {!isMobile ? <AsideMenu /> : null}
            {isMobile ? <TopMenu /> : null}
            <main>
                {children}
            </main>
        </>
    );
};

export default PageWrap;
