const initialState = {
  socketConnected: false
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SOCKET_CONNECTION':
      return {
        ...state,
        socketConnected: action.payload
      };
    default:
      return state;
  }
};

export default globalReducer;
