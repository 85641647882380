// videoReducer.js

import {
    UPDATE_VIDEO_STATUS_REQUEST,
    UPDATE_VIDEO_STATUS_SUCCESS,
    UPDATE_VIDEO_STATUS_FAILURE,
} from '../actions/types';

const initialState = {
    loading: false,
    success: false,
    error: null,
    responseData: null,
    chatInfo: {
        webCam: {
            ready: false,
            stream: null,
            data: {
                device: "no-cam", 
                resolution: "hd"
            }
        },
    },
};

const videoReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_VIDEO_STATUS_REQUEST:
            return { ...state, loading: true, error: null, success: false, responseData: null };
        case UPDATE_VIDEO_STATUS_SUCCESS:
            return { ...state, loading: false, success: true, error: null, responseData: action.payload };
        case UPDATE_VIDEO_STATUS_FAILURE:
            return { ...state, loading: false, success: false, error: action.payload, responseData: null };
        default:
            return state;
    }
};

export default videoReducer;
