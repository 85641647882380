export function convertDatetime(inputDatetime) {
    // Parse the input datetime string
    const parsedDatetime = new Date(inputDatetime);

    // Ensure the parsed datetime is valid
    if (isNaN(parsedDatetime.getTime())) {
        return "Invalid datetime format";
    }

    // Get day, month, year, hours, and minutes
    const day = parsedDatetime.getDate();
    const month = parsedDatetime.toLocaleString('en-us', { month: 'short' });
    const year = parsedDatetime.getFullYear();
    const hours = parsedDatetime.getHours();
    const minutes = parsedDatetime.getMinutes();

    // Format hours and minutes with leading zeros
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

    // Create the final formatted string
    const formattedDatetime = `${day} ${month} ${year}, ${formattedHours}:${formattedMinutes}`;

    return formattedDatetime;
}