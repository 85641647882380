import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import PageWrap from '../../app/PageWrap';
import classes from './style/videochat.module.scss';
import PreChat from './components/PreChat';
import { useSelector } from 'react-redux';

const Videochat = () => {
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state.user.info);
    const user = JSON.parse(localStorage.getItem("userInfo"));

    useEffect(() => {
        if(!user || (userInfo && !userInfo?.isLoggedIn)){
            navigate('/login')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, userInfo]);

    return (
        <PageWrap>
            <section className={classes["chat"]}>
                <div className="container">
                    <div className='main-container'>
                        <PreChat />
                    </div>
                </div>
            </section>
        </PageWrap>
    );
}

export default Videochat;
